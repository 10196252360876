import React from 'react';

import featuredImage from './assets/FeaturedImage_GolangChecklist_LP.jpg';
import mainImage from './assets/background-image.svg';
import whitePaperPdf from './assets/CodiLime-white-paper-Golang-checklist.pdf';

export const lpProps = {
  seoProps: {
    title: 'Golang for your Software Project — Checklist | CodiLime',
    description:
      'Get the practical checklist that will help you determine if the Go programming language is a good fit for your software project.',
    featuredImage: featuredImage,
  },
  headerProps: {
    title: 'Is Golang the right choice for your project?',
    id: 'golang-checklist-page',
  },
  formProps: {
    redirectUrl: 'https://resources.codilime.com/hubfs/CodiLime%20white%20paper%20-%20Golang%20checklist.pdf',
    policyInfoType: 'type3',
    mainTitle: 'Grab your free checklist!',
    id: 'golang-checklist-form',
    formId: '45cf9e49-7887-4e53-a020-ad8cfb77ad17',
    whitePaperPdf,
    thankYouMessage: (
      <>
        <h3>Thank you for submitting the form!</h3>
        <p>
          Your free checklist is already waiting for you. You can read it on the spot or download it for later.
          <br />
          <br />
          If you have any questions, let us know.
        </p>
      </>
    ),
  },
  learnAboutProps: {
    youWillGetToKnow: [
      'Learn the benefits of using Go',
      'Dive into project types and characteristics',
      'Get to know how Golang can meet your needs',
      'Figure out if Go is a fit for you',
    ],
    summary:
      'Get the practical checklist that will help you get started with UX design for network applications. Capitalize on CodiLime’s 11 years of experience designing digital products for telcos and the network industries.',
    promise: <strong>With our checklist you will:</strong>,
    cta: (
      <>
        Fill in the form and get your <strong>free Golang checklist</strong> now!
      </>
    ),
    imageProps: {
      src: mainImage,
      alt: 'UX design checklist',
    },
  },
};

import React, { useState } from 'react';

import { isMobile } from 'react-device-detect';
import { paths } from '@src/constants/pathsEnum';

import { Button } from '@commons/button/Button';
import { ModalPortal } from '@commons/modal-portal/ModalPortal';
import ContactPopup from '@commons/contact-popup/ContactPopup';

import envelopeImg from '@images/ci-cd-pipeline/cicdEnvelope.svg';

import * as styles from './thank-you.module.scss';

export const ThankYou = ({ thankYouMessage }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img loading="lazy" src={envelopeImg} alt="Thank you!" />
      </div>
      {thankYouMessage}
      {!isMobile ? (
        <>
          <Button onClick={() => setShowModal(true)} label="Contact us" />
          <ModalPortal>
            <ContactPopup showModal={showModal} setShowModal={setShowModal} />
          </ModalPortal>
        </>
      ) : (
        <Button href={`${paths.CONTACT}#contact`} label="Contact us" />
      )}
    </div>
  );
};

import React, { useState } from 'react';

import HubspotForm from 'react-hubspot-form';

import { isDesktop } from 'react-device-detect';
import { downloadPdfFile } from '@src/utils/dowloadPdfFile';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { LoadingSpinner } from '@commons/loading-spinner/LoadingSpinner';
import { ThankYou } from './ThankYou';
import { privatePolicyInfo } from '@commons/append-tooltip/payload';
import { appendTooltip } from '@commons/append-tooltip/appendTooltip';

import * as styles from './form.module.scss';

export const LandingPageForm = (props) => {
  const { redirectUrl, policyInfoType, mainTitle, id, formId, whitePaperPdf, thankYouMessage } = props;

  const [formSubmitOk, setFormSubmitOk] = useState(false);
  const policyInfo = privatePolicyInfo[policyInfoType];
  return (
    <StandardSection
      id={id}
      className={styles.container}
      classNames={{ outerWrapper: styles.outerWrapper, innerWrapper: styles.innerWrapper }}
    >
      {formSubmitOk ? (
        <ThankYou thankYouMessage={thankYouMessage} />
      ) : (
        <>
          <h3 className={styles.title}>{mainTitle}</h3>
          <div className="old-hubspot-form">
            <HubspotForm
              region="na1"
              portalId="5206705"
              formId={formId}
              loading={<LoadingSpinner />}
              onReady={() => appendTooltip(`#${id}`, policyInfo)}
              onSubmit={() => undefined}
              onFormSubmitted={() => {
                if (isDesktop) {
                  window.open(redirectUrl, '_blank', 'noopener noreferrer');
                } else {
                  downloadPdfFile(whitePaperPdf);
                }
                setFormSubmitOk(true);
              }}
            />
          </div>
        </>
      )}
    </StandardSection>
  );
};

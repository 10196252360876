import React from 'react';

import { ListRender } from '@commons/list-render/ListRender';

import * as styles from './you-will-get-to-know.module.scss';

export const LearnAbout = (props) => {
  const { summary, promise, cta, youWillGetToKnow, imageProps } = props;
  return (
    <div className={styles.container}>
      <p className={styles.description}>{summary}</p>
      <p className={styles.listHeading}>{promise}</p>
      <ListRender data={youWillGetToKnow} withArrows lime />
      <p className={styles.cta}>{cta}</p>
      <div className={styles.imageContainer}>
        <img loading="lazy" src={imageProps.src} alt={imageProps.alt} />
      </div>
    </div>
  );
};

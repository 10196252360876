import React from 'react';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';

import { Header } from '@commons/header/Header';

import { LandingPageForm } from './form/LandingPageForm';
import { LearnAbout } from './learn-about/LearnAbout';

import { BackgroundImage } from './images/BackgroundImage';

import * as styles from './landing-page-with-form.module.scss';

export const LandingPageWithForm = (props) => {
  const { layoutProps, seoProps, headerProps, learnAboutProps, formProps, customBackgroundImage } = props;
  return (
    <Layout layout="landing" {...layoutProps} contactFormType={false}>
      <SEO {...seoProps} />
      <Header
        headerFigures={false}
        logoContainerDark={true}
        className={styles.landingPageHeader}
        logoContainerClassName={styles.logoContainerHeader}
        headerMainContentClassName={styles.headerMainContent}
        headerOuterWrapper={styles.outerWrapper}
        {...headerProps}
      />
      <div className={styles.landingPageBody}>
        <LearnAbout {...learnAboutProps} />
        <LandingPageForm {...formProps} />
        {customBackgroundImage || <BackgroundImage className={styles.backgroundImage} />}
      </div>
    </Layout>
  );
};

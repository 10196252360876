import React from 'react';

export const BackgroundImage = ({ className }) => (
  <svg
    className={className}
    width="448"
    height="448"
    viewBox="0 0 448 448"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M261.769 223.828L254.161 231.437L246.407 223.683L245.02 225.07L252.774 232.824L245.164 240.433L246.578 241.846L254.187 234.237L261.94 241.99L263.327 240.603L255.574 232.85L263.183 225.241L261.769 223.828Z"
      fill="#A3ED00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M166.184 211.704L173.938 219.457L172.551 220.844L164.797 213.091L157.188 220.7L155.775 219.287L163.384 211.678L155.631 203.924L157.018 202.538L164.771 210.291L172.38 202.682L173.794 204.095L166.184 211.704Z"
      fill="#A3ED00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M196.942 249.19L204.695 256.943L203.308 258.33L195.555 250.577L187.946 258.186L186.532 256.773L194.142 249.164L186.388 241.41L187.775 240.023L195.528 247.777L203.138 240.168L204.551 241.581L196.942 249.19Z"
      fill="#A3ED00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M189.681 142.128L182.072 149.737L174.319 141.983L172.932 143.37L180.685 151.124L173.076 158.733L174.489 160.146L182.099 152.537L189.852 160.291L191.239 158.904L183.486 151.15L191.095 143.541L189.681 142.128Z"
      fill="#A3ED00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.7573 125.788L81.1485 133.396L73.3951 125.643L72.0078 127.03L79.7612 134.784L72.152 142.393L73.5657 143.806L81.1748 136.197L88.9282 143.951L90.3155 142.563L82.5621 134.81L90.1709 127.201L88.7573 125.788Z"
      fill="#A3ED00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M303.101 125.788L295.492 133.397L287.738 125.643L286.351 127.03L294.105 134.784L286.496 142.393L287.909 143.806L295.518 136.197L303.271 143.951L304.658 142.564L296.905 134.81L304.514 127.201L303.101 125.788Z"
      fill="#A3ED00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M98.9017 279.948L106.655 287.701L105.268 289.088L97.5144 281.335L89.9056 288.944L88.4923 287.53L96.1011 279.922L88.3478 272.168L89.7351 270.781L97.4884 278.534L105.098 270.925L106.511 272.338L98.9017 279.948Z"
      fill="#A3ED00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.397 232.85L119.15 240.603L117.763 241.99L110.009 234.237L102.401 241.846L100.987 240.433L108.596 232.824L100.843 225.07L102.23 223.683L109.983 231.436L117.592 223.828L119.005 225.241L111.397 232.85Z"
      fill="#A3ED00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M145.999 263.608L153.753 271.361L152.365 272.748L144.612 264.995L137.003 272.604L135.59 271.191L143.199 263.582L135.445 255.828L136.833 254.441L144.586 262.194L152.195 254.585L153.609 255.999L145.999 263.608Z"
      fill="#A3ED00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M213.282 215.549L221.035 223.302L219.648 224.689L211.895 216.936L204.286 224.545L202.873 223.132L210.482 215.523L202.728 207.77L204.115 206.382L211.869 214.136L219.478 206.526L220.891 207.94L213.282 215.549Z"
      fill="#A3ED00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M260.38 198.247L268.133 206.001L266.746 207.388L258.992 199.635L251.384 207.243L249.97 205.83L257.579 198.221L249.826 190.468L251.213 189.081L258.966 196.834L266.575 189.225L267.988 190.639L260.38 198.247Z"
      fill="#A3ED00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M244.04 265.53L251.793 273.284L250.406 274.671L242.652 266.918L235.044 274.527L233.63 273.113L241.239 265.504L233.485 257.751L234.873 256.363L242.626 264.117L250.236 256.508L251.649 257.921L244.04 265.53Z"
      fill="#A3ED00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M348.227 264.519L355.98 272.272L354.593 273.659L346.84 265.906L339.231 273.515L337.818 272.102L345.427 264.492L337.673 256.739L339.06 255.352L346.814 263.106L354.423 255.496L355.836 256.91L348.227 264.519Z"
      fill="#A3ED00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M297.865 248.229L305.619 255.982L304.232 257.369L296.478 249.616L288.869 257.225L287.456 255.811L295.065 248.203L287.312 240.449L288.699 239.062L296.452 246.815L304.061 239.206L305.474 240.62L297.865 248.229Z"
      fill="#A3ED00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M346.885 212.665L354.638 220.419L353.251 221.806L345.498 214.053L337.889 221.661L336.476 220.248L344.085 212.639L336.331 204.886L337.718 203.499L345.472 211.252L353.081 203.643L354.494 205.056L346.885 212.665Z"
      fill="#A3ED00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M381.123 197.783L388.877 205.536L387.489 206.924L379.736 199.17L372.127 206.779L370.714 205.366L378.323 197.757L370.569 190.004L371.957 188.616L379.71 196.37L387.319 188.761L388.732 190.174L381.123 197.783Z"
      fill="#A3ED00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M381.438 231.308L389.191 239.062L387.804 240.449L380.051 232.696L372.442 240.305L371.028 238.891L378.637 231.282L370.884 223.529L372.271 222.142L380.025 229.895L387.634 222.286L389.047 223.699L381.438 231.308Z"
      fill="#A3ED00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M404.869 272.731L397.26 280.34L389.506 272.586L388.119 273.974L395.873 281.727L388.263 289.336L389.677 290.75L397.286 283.141L405.039 290.894L406.426 289.506L398.673 281.753L406.282 274.145L404.869 272.731Z"
      fill="#A3ED00"
    />
  </svg>
);
